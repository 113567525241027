var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSignedIn === true
    ? _c(
        "v-app",
        [
          _c("app-bar", {
            attrs: {
              client: _vm.client,
              "selectable-project": !_vm.projectSelectorDisabled,
            },
          }),
          _c("menu-drawer"),
          _vm.client
            ? _c("router-view", {
                attrs: {
                  app: "",
                  client: _vm.client,
                  projectName: _vm.projectName,
                },
              })
            : _vm._e(),
          _c("tutti-snackbar", { ref: "snackbar", attrs: { timeout: 5000 } }),
        ],
        1
      )
    : _vm.isSignedIn === false
    ? _c(
        "v-app",
        [
          _c("auth-view", {
            attrs: { client: _vm.client, usersExist: _vm.usersExist },
          }),
          _c("tutti-snackbar", { ref: "snackbar", attrs: { timeout: 5000 } }),
        ],
        1
      )
    : _c(
        "v-app",
        [_c("tutti-snackbar", { ref: "snackbar", attrs: { timeout: 5000 } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }