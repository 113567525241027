<template>
    <v-navigation-drawer v-model="shown" app :mini-variant="miniVariant">
        <v-list nav dense>
            <v-list-item-group
                v-for="group,i in listItems"
                :key="`group-${i}`"
                active-class="indigo--text text--accent-4">

                <v-subheader v-if="group[0] && !miniVariant" v-html="group[0]" />

                <div v-for="item,j in group[1]" :key="`group-${i}-item-${j}`">
                    <v-tooltip
                        right
                        v-if="typeof(item)==='object' && !Array.isArray(item)"
                        :disabled="!miniVariant"
                    >
                        <template #activator="{ attrs, on }">
                            <v-list-item
                                v-bind="attrs"
                                v-on="on"
                                :to="item.to || undefined"
                                :href="item.href || undefined"
                                :target="item.target || undefined"
                                >
                                
                                <v-list-item-icon v-if="item.icon"><v-icon v-html="item.icon" /></v-list-item-icon>
                                <v-list-item-title v-html="item.title" />
                                <v-list-item-action v-if="item.target"><v-icon small>mdi-launch</v-icon></v-list-item-action>

                            </v-list-item>
                        </template>
                        <span>{{ item.title }}</span>
                    </v-tooltip>

                    <v-tooltip
                        right
                        v-else
                        :disabled="!miniVariant"
                    >
                        <template #activator="{ attrs, on }">
                            <v-list-group
                                v-bind="attrs"
                                v-on="on"
                                :prepend-icon="item[0].icon"
                                :value="false"
                                @click="miniVariant=false"
                                >
                                <template v-slot:activator> <v-list-item-title v-html="item[0].title" /> </template>

                                <v-list-item
                                    v-for="_item,k in item[1]"
                                    :key="`group-${i}-item-${j}-${k}`"
                                    class="pl-6"
                                    :to="_item.to || undefined"
                                    :href="_item.href || undefined"
                                    :target="_item.target || undefined"
                                    >

                                    <v-list-item-icon><v-icon v-html="_item.icon" /></v-list-item-icon>
                                    <v-list-item-title v-html="_item.title" />

                                </v-list-item>

                            </v-list-group>
                        </template>
                        {{ item[0].title }}
                    </v-tooltip>
                </div>
            </v-list-item-group>

            <v-divider />

            <v-list-item @click="miniVariant = !miniVariant">
                <v-list-item-action>
                    <v-icon v-html="miniVariant ? 'mdi-chevron-right' : 'mdi-chevron-left'"></v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Fold</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { listItems } from './ConsoleMenuDrawerInfo'
export default {
    data: () => ({
        listItems,
        shown: true,
        miniVariant: true,
    }),
    methods: {
        toggleSelf() {
            this.shown = !this.shown;
        }
    },
    created() {
        this.$root.$on('drawer-icon-click', this.toggleSelf);
    }
}
</script>
