export const listItems = [
        [
            null,
            [
                {
                    to: '/console/dashboard',
                    icon: 'mdi-view-dashboard',
                    title: 'Dashboard'
                }
            ]
        ],
        [
            'DESIGN & TEST',
            [
                {
                    to: '/console/template/',
                    icon: 'mdi-application-outline',
                    title: 'Templates'
                },
                {
                    to: '/console/flow',
                    icon: 'mdi-wrench',
                    title: 'Scheme'
                },
                {
                    to: '/console/storage',
                    icon: 'mdi-database',
                    title: 'File Storage'
                }
            ]
        ],
        [
            'PUBLISH & COLLECT',
            [
                {
                    to: '/console/response',
                    icon: 'mdi-comment-check-outline',
                    title: 'Responses'
                },
                [
                    {
                        icon: 'mdi-account-group',
                        title: 'Worker Platforms'
                    },
                    [
                        //{
                        //    to: '/console/platform/tutti-market/',
                        //    icon: 'mdi-music',
                        //    title: 'Tutti Market'
                        //},
                        {
                            to: '/console/platform/mturk/',
                            icon: 'mdi-aws',
                            title: 'Amazon MTurk'
                        }
                    ]
                ]
            ],
        ],
        [
            'OTHERS',
            [
                {
                    to: '/console/version-log',
                    icon: 'mdi-tag-text-outline',
                    title: 'Version Logs'
                },
                {
                    href: 'https://iflb.github.io/tutti/',
                    target: '_blank',
                    icon: 'mdi-file-document-outline',
                    title: 'Documentation'
                }
            ]
        ],
    ];
