<template>
    <v-app-bar color="indigo" dark app dense clipped-right>
        <v-app-bar-nav-icon @click="sendDrawerIconClick"></v-app-bar-nav-icon>
        
        <v-toolbar-title>Tutti Console <span class="text-body-1">v{{ currentVersion }}</span></v-toolbar-title>

        <v-spacer></v-spacer>

        <v-autocomplete
            hide-details
            cache-items
            solo-inverted
            hide-no-data
            dense
            rounded
            v-show="selectableProject"
            v-model="projectName"
            :items="projectNames"
            label="Select existing project">
        </v-autocomplete>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    dark
                    small
                    icon
                    v-show="selectableProject"
                    v-on="on"
                    v-bind="attrs"
                    @click="$refs.dialogCreateProject.show()">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Create new project...</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    text
                    style="text-transform: none"
                    v-on="on"
                    v-bind="attrs"
                >
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{ client.accountInfo.userName }}
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item @click="$refs.dialogChangePassword.show()">
                    <v-list-item-icon><v-icon>mdi-key-variant</v-icon></v-list-item-icon>
                    <v-list-item-title>Change password</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$root.$emit('select-sign-out')">
                    <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                    <v-list-item-title>Sign out</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$refs.dialogSignUp.show()">
                    <v-list-item-icon><v-icon>mdi-account-plus</v-icon></v-list-item-icon>
                    <v-list-item-title>Create another account</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$refs.dialogDeleteAccount.show()">
                    <v-list-item-icon><v-icon color="error">mdi-account-off</v-icon></v-list-item-icon>
                    <v-list-item-title class="error--text">Delete account</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <server-status-menu-button :client="client"></server-status-menu-button>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    :plain="!isEventLogWindowOpen"
                    v-on="on"
                    v-bind="attrs"
                    @click="openEventLogWindow"
                >
                    <v-icon :color="isEventLogWindowOpen ? 'yellow darken-2' : ''">mdi-lightning-bolt</v-icon>
                </v-btn>
            </template>
            <span>Open DUCTS communication log</span>
        </v-tooltip>

        <dialog-create-project ref="dialogCreateProject" :client="client" @complete="refreshProjects" />
        <dialog-sign-up ref="dialogSignUp" :client="client" />
        <dialog-change-password ref="dialogChangePassword" :client="client" />
        <tutti-dialog-alert
            ref="dialogDeleteAccount"
            @confirm="deleteAccount"
        >
            <template #title>
                <v-icon left color="error">mdi-alert</v-icon>
                Delete this account?
            </template>
            <template #body>
                <v-card-text>
                    Are you sure you wish to delete the account "<b>{{ client.accountInfo.userName }}</b>"?
                    This operation cannot be undone.<br>
                    * You will also be automatically signed out.
                </v-card-text>
            </template>
        </tutti-dialog-alert>

    </v-app-bar>
</template>

<script>
import { currentVersion } from '@/lib/version'
import tuttiWindowMessage from '@/lib/window-message'
import ServerStatusMenuButton from './ConsoleServerStatusMenuButton'
import DialogCreateProject from './DialogCreateProject'
import DialogSignUp from './DialogSignUp'
import DialogChangePassword from './DialogChangePassword'
import TuttiDialogAlert from '@/components/ui/TuttiDialogAlert'

export default {
    components: {
        ServerStatusMenuButton,
        DialogCreateProject,
        DialogSignUp,
        DialogChangePassword,
        TuttiDialogAlert,
    },
    data: () => ({
        currentVersion,

        projectNames: [],
        projectName: "",
        isEventLogWindowOpen: false,
    }),
    props: {
        client: { default: null },
        selectableProject: { type: Boolean, default: true }
    },
    methods: {
        sendDrawerIconClick() { this.$root.$emit('drawer-icon-click'); },
        sendProjectInfoUpdate() { this.$root.$emit('project-info-update', this.projectNames, this.projectName); },

        openEventLogWindow() {
            let eventLogWindow = window.open('/event-log', 'eventLogWindow', ';');
            this.isEventLogWindowOpen = true;
            const handler = tuttiWindowMessage.setHandler((msg) => {
                if(msg.evt==='init') {
                    tuttiWindowMessage.send(eventLogWindow, 'events', this.client._duct.EVENT);
                    let sendLogInterval = setInterval(() => {
                        if(eventLogWindow.closed) {
                            clearInterval(sendLogInterval);
                            tuttiWindowMessage.removeHandler(handler);
                            this.isEventLogWindowOpen = false;
                        } else {
                            tuttiWindowMessage.send(eventLogWindow, 'logs', this.client.logger.log);
                        }
                    }, 1000);
                }
            });
        },
        async refreshProjects() {
            const projects = await this.client.resource.listProjects();
            this.projectNames = projects.map((prj) => (prj.name));
            this.projectName = window.localStorage.getItem("tuttiProject") || null;
        },
        async deleteAccount() {
            await this.client.resource.deleteAccount({ user_id: this.client.accountInfo.userId });
            await this.client.resource.signOut();
            window.location.reload();
        }
    },
    watch: {
        projectNames() { this.sendProjectInfoUpdate(); },
        projectName() { this.sendProjectInfoUpdate(); }
    },
    created() {
        this.client.invokeOnOpen(async () => {
            await this.refreshProjects();
        });
    }
}
</script>
